import React, { useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import { ButtonContent } from "../boton/ButtonContent";
import { BsCalendar4Range, BsClockHistory } from "react-icons/bs";
import Navmenu from "./NavMenu";
import { FiPhoneCall } from "react-icons/fi";

const HeaderTwo = () => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <header className="absolute right-[3%] left-[3%] z-[10]">
      <div className="flex">
        <div className="w-full mr-0 md:w-[35%] lg:w-[25%] lg:mr-10">
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt="logo"
            loading="lazy"
            className="w-[80%] mx-auto pt-3 pb-5"
          />
        </div>

        <div className="hidden md:block w-[65%] lg:w-[75%] self-center">
          <div className="flex">
            <div className="w-[55%] lg:w-[75%]">
              <div className="grid grid-cols-1 lg:grid-cols-2">
                <div className="flex flex-wrap text-white">
                    <span>Phone Numbers</span>
                  {rpdata?.dbPrincipal?.phones.map((phone, index) => {
                    return (
                      <a href={`tel:+1${phone.phone}`} key={index}>
                        <li className="py-2 flex items-center hover:text-sky-500">
                          <FiPhoneCall fontSize={25} />
                          <h5 className="pl-3 text-[21px]">
                            {phone.phone} {phone.name}
                          </h5>
                        </li>
                      </a>
                    );
                  })}
                </div>

                <div className="hidden text-white  lg:flex lg:flex-col lg:justify-between">
                  <div className="flex gap-2">
                    <div className=" self-center mr-3">
                      <BsCalendar4Range color="white" fontSize={35} />
                    </div>
                    <div>
                      <p className="-mb-3">Workdays</p>
                      <h5 className="text-[21px]">
                        {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                      </h5>
                    </div>
                  </div>

                  <div className="flex gap-2">
                    <div className=" self-center mr-3">
                      <BsClockHistory color="white" fontSize={35} />
                    </div>
                    <div>
                      <p className="-mb-3">Work Hours</p>
                      <h5 className="text-[21px]">
                        {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[45%] lg:w-[25%] text-end border-l ml-0 lg:ml-7">
              <ButtonContent />
            </div>
          </div>
        </div>
      </div>
      {rpdata?.simpleWidgets?.[3]?.activo ? null : (
        <div className="bgBloque px-3 py-4">
          <div className="flex justify-between items-center">
            <nav>
              <Navmenu />
            </nav>
            <ul className="flex justify-center space-x-7 pr-5">
              {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <li key={index} className="text-white">
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={`fab fa-${item.icon}`} aria-hidden="true" />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default HeaderTwo;
